.objectHover {
	opacity: 0.3 !important;
	mix-blend-mode: multiply !important;
}

.studentRead {
	opacity: 0.5 !important;
	mix-blend-mode: multiply !important;
	fill: rgb(131, 225, 107) !important;
}

.clickable {
	pointer-events: auto !important;
	-webkit-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;

	* {
		pointer-events: auto !important;
	}

	&.largerClickArea * {
		pointer-events: fill !important;
	}
}

.extendedContent {
	pointer-events: all;
	> div {
		height: 100%;
	}
}