.synopsisWindow {
  display: flex;
  flex-direction: column-reverse;


  .tab_box {
    display: flex;
    margin: 0px 10px;
    gap: 20px;
    flex: .7;
    .tab {
      min-width: 50px;
      padding: 10px;
      background-color: #1abcbd;
      color: #fff;
      border-radius: 5px;
      text-align: center;
      font-size: 3.5vmin;
      cursor: pointer;
      // width: 1%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        background-color: #d65f84;
      }
    }
  }

  .list {
    width: 100%;
    margin-right: auto;
    height: 100%;
    overflow: auto;
    &>div {
      height: 100%;
    }
  }

  // For 108上直書 & 國語樣式
  &.wordSection,
  &.ChineseElementary {
    flex-direction: row;
    justify-content: space-between;
    height: 100%;

    .tab_box {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      &.mobile{
        flex: 1 0 0;
      }
      // align-content: flex-start;
      // width: 15%;
      // height: 100%;
      overflow: hidden;

      .tab {
        writing-mode: vertical-rl;
        width: 1%;
      }
    }

    .list {
      flex: 4 0 0;
      // width: 85%;
    }
  }

  // For 國文樣式
  &.ChineseSecondary {
    flex-direction: row-reverse;

    .tab_box {
      // max-width: 50px;
      column-gap: 10px;
      display: flex;
      flex-direction: column;
      justify-content: initial;
      margin-left: auto;
      flex-wrap: wrap;
    }

    .tab {
      writing-mode: vertical-rl;
      text-orientation: upright;
      margin: 0px 0px;
    }

    .list {
      width: 83%;
      margin-right: inherit;
      margin-left: 3vmin;
    }
  }
}